import APIService from './APIService';

export default {
  index() {
    return APIService.apiCall().get('/analises-setorizadas/index');
  },
  getAnalisesSetorizadasFavoritadas() {
    return APIService.apiCall().get('/analises-setorizadas/get-favoritadas');
  },
  getAnaliseSetorizada(id) {
    return APIService.apiCall().get(`/analises-setorizadas/get-analise/${id}`);
  },
  getResultadoAnaliseSetorizada(id) {
    return APIService.apiCall().get(
      `/analises-setorizadas/get-resultado/${id}`
    );
  },
  save(postData) {
    return APIService.apiCall().post(
      '/analises-setorizadas/save',
      JSON.stringify(postData)
    );
  },
  deletar(id) {
    return APIService.apiCall().get(`/analises-setorizadas/excluir/${id}`);
  },
  executar(id) {
    return APIService.apiCall().get(`/analises-setorizadas/executar/${id}`);
  },
  favoritar(id) {
    return APIService.apiCall().get(`/analises-setorizadas/favoritar/${id}`);
  }
};
