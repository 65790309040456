<template>
  <v-container
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <material-wizard
        title="Salvar Análise Setorizada"
        subtitle="Selecione uma setorização e efetue análises dos itens presentes dentro dos setores gerados."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content
              ref="FirstTabContent"
              :items="analise"
              @data:updated="handleTabContentUpdated"
            />
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <second-tab-content
            :month="analise.data"
            :items="analise"
            :filtrosSelecionados="[filtro]"
            :campoSelecionadoCalculo="campo"
            :bdgdVersion="analise.bdgdVersion"
            @data:updated="handleTabContentUpdated"
          />
        </v-tab-item>
        <v-tab-item class="pb-1">
          <third-tab-content
            :colors="analise.cores"
            :midpoint="analise.setorizacao.numero_setores"
            @data:updated="handleTabContentUpdated"
          />
        </v-tab-item>
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import AnalisesSetorizadasService from '@/services/AnalisesSetorizadasService';
import tourMixins from '@/mixins/tourMixins';
import dateFormatterMixins from '@/mixins/dateFormatterMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [tourMixins, dateFormatterMixins],
  components: {
    MaterialWizard: () => import('@/components/base/MaterialWizard'),
    FirstTabContent: () =>
      import('@/components/analises-setorizadas/tabs/FirstTabContent.vue'),
    SecondTabContent: () =>
      import('@/components/analises-setorizadas/tabs/SecondTabContent.vue'),
    ThirdTabContent: () =>
      import('@/components/analises-setorizadas/tabs/ThirdTabContent.vue')
  },
  data: () => ({
    tab: 0,
    tabs: ['Setorização', 'Filtre itens', 'Selecione cores'],
    basesLiberadasCampos: {},
    loading: false,
    analise: {
      id: null,
      nome: null,
      data: null,
      mes: null,
      bdgdVersion: null,
      setorizacao: {
        id: null,
        numero_setores: 0
      },
      basesSelecionadas: [],
      campoCalculoSelecionado: {
        funcao: 'SOMAR',
        campo: ''
      },
      filtros: {},
      cores: {
        corInicio: '#A2F19F',
        corFim: '#037A02'
      }
    },
    arrTourPrimeiraTab: [
      {
        element: '#fs-nome-analise-setorizada',
        intro: 'Aqui você deverá inserir um nome para sua nova análise.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#analise-setorizada-bdgd-versao',
        intro: 'Você deve selecionar o mês e a versão da BDGD.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#selecao-setorizacao',
        intro: 'Selecione a setorização que será utilizada na sua análise.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#setorizacao-mapa',
        intro:
          'Aqui você irá visualizar um mapa com a setorização selecionada, podendo acessar cada um dos setores que serão utilizados em sua análise.',
        scrollTo: 'tooltip',
        position: 'bottom'
      }
    ],
    arrTourSegundaTab: [
      {
        element: '#selecao-bases-geograficas',
        intro:
          'Aqui você deverá selecionar bases geográficas, um campo numérico para aplicar a função de análise e opcionalmente filtrar dados nessas bases.',
        scrollTo: 'tooltip',
        position: 'bottom'
      }
    ],
    arrTourTerceiraTab: [
      {
        element: '#selecionar-cores',
        intro:
          'Aqui você poderá configurar as cores que serão utilizadas nos setores do seu mapa coroplético.',
        scrollTo: 'tooltip',
        position: 'bottom'
      }
    ],
    filtro: [],
    campo: {}
  }),
  mounted() {
    this.iniciarTourPrimeiraTab();
    if (this.$route.params.id)
      this.carregarAnaliseSetorizada(this.$route.params.id);
  },
  computed: {
    availableSteps() {
      let steps = [];

      if (this.tab1IsValid) {
        steps.push(0);
      }

      if (this.tab2IsValid) {
        steps.push(1);
      }

      if (this.tab3IsValid) {
        steps.push(2);
      }

      return steps;
    },

    tab1IsValid() {
      const { nome, mes, bdgdVersion, setorizacao } = this.analise;

      return (
        nome !== null &&
        mes !== null &&
        bdgdVersion !== null &&
        setorizacao &&
        setorizacao.id > 0
      );
    },

    tab2IsValid() {
      const { basesSelecionadas, campoCalculoSelecionado } = this.analise;

      return (
        this.tab1IsValid &&
        basesSelecionadas.length > 0 &&
        campoCalculoSelecionado.funcao &&
        campoCalculoSelecionado.campo
      );
    },

    tab3IsValid() {
      const { cores } = this.analise;

      return (
        this.tab1IsValid &&
        this.tab2IsValid &&
        cores.corInicio &&
        cores.corFim &&
        cores.corInicio !== cores.corFim
      );
    },

    disabledBtnFinalizar() {
      if (this.tab === 0) return !this.tab1IsValid;
      if (this.tab === 1) return !this.tab2IsValid;
      if (this.tab === 2) return !this.tab3IsValid;
      return true;
    }
  },
  methods: {
    async next(valid) {
      if (!valid) return;

      if (this.tab === 0 && !this.tab1IsValid) {
        return;
      }

      if (this.tab === 1 && !this.tab2IsValid) {
        return;
      }

      if (this.tab === 2) {
        if (!this.tab3IsValid) {
          return;
        }

        const sucesso = await this.salvarAnaliseSetorizada();
        if (!sucesso) {
          return;
        }

        this.$router.push({
          name: routes.state.appRoutes['ANALISES_SETORIZADAS_HISTORICO']
        });
      } else {
        this.tab++;
      }
    },

    handleTabContentUpdated(event) {
      this.analise = Object.assign(this.analise, event);
    },

    async salvarAnaliseSetorizada() {
      let sucesso = false;
      this.loading = true;

      try {
        const {
          nome,
          basesSelecionadas,
          campoCalculoSelecionado,
          filtros,
          cores,
          data,
          bdgdVersion,
          setorizacao,
          basesLiberadasCampos
        } = this.analise;

        let analise = {
          nome,
          id: this.$route.params.id ? this.$route.params.id : null,
          bases_dados_geograficas_config: JSON.stringify(basesSelecionadas),
          campo_config: JSON.stringify(campoCalculoSelecionado),
          filtros_config: JSON.stringify(filtros),
          cores_config: JSON.stringify(cores),
          setorizacao_id: setorizacao.id,
          data_registro: data,
          versao: bdgdVersion,
          bases_liberadas_campos: JSON.stringify(basesLiberadasCampos)
        };

        sucesso = await AnalisesSetorizadasService.save(analise);

        this.$toast.success('Análise Setorizada salva com sucesso.', '', {
          position: 'topRight'
        });
      } catch (e) {
        this.$toast.error('Erro ao salvar a análise setorizada.', '', {
          position: 'topRight'
        });
        console.log(e);
      } finally {
        this.loading = false;
      }

      return sucesso;
    },

    iniciarTourPrimeiraTab() {
      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;

      let onBeforeExitFunction = {
        func: () => {
          this.tab = 1;
          this.initTourSegundaTab();
        }
      };

      this.iniciarTour(this.arrTourPrimeiraTab, [], [], [onBeforeExitFunction]);
    },

    initTourSegundaTab() {
      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;

      let onBeforeExitFunction = {
        func: () => {
          this.tab = 2;
          this.initTourTerceiraTab();
        }
      };

      this.iniciarTour(this.arrTourSegundaTab, [], [], [onBeforeExitFunction]);
    },

    initTourTerceiraTab() {
      this.doneLabel = 'Finalizar';
      this.desativarTourAoSair = true;

      let onBeforeExitFunction = {
        func: () => (this.tab = 0)
      };

      this.iniciarTour(this.arrTourTerceiraTab, [], [], [onBeforeExitFunction]);
    },

    carregarAnaliseSetorizada(id) {
      this.loading = true;

      AnalisesSetorizadasService.getAnaliseSetorizada(id)
        .then((response) => {
          const setorizacaoData = response.data.data;

          this.analise = {
            id: setorizacaoData.id,
            nome: setorizacaoData.nome,
            data: setorizacaoData.mes,
            mes: this.formatMonthToPicker(setorizacaoData.mes),
            bdgdVersion: setorizacaoData.versao,
            setorizacao: setorizacaoData.setorizacao,
            basesSelecionadas: setorizacaoData.bases,
            basesGeograficas: setorizacaoData.bases_dados_geograficas,
            campoCalculoSelecionado: setorizacaoData.campo,
            filtros: setorizacaoData.filtros,
            cores: setorizacaoData.cores,
            basesLiberadasCampos: setorizacaoData.bases_liberadas_campos
          };

          this.filtro = setorizacaoData.filtros;
          this.campo = setorizacaoData.campo;
        })
        .catch((e) => {
          this.$toast.error(
            'Erro ao carregar os dados da analise setorizada.',
            '',
            { position: 'topRight' }
          );
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
          if (this.$refs.FirstTabContent)
            this.$refs.FirstTabContent.fillInputs();
        });
    }
  }
};
</script>
